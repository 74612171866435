$main-color: #3B8B8C; // #E74C3C #274F6E #27444A
$second-color: #C2414F; // #C27A59

$text-color: #f2f1ef; // #FFF #f2f1ef #f8f8f7
$text-color-dark: #000; // #102422
$emphase:  $second-color; //#FFFF66 #C27A59 #C2414F #E83861;
$sidebar: #274F6E;
$tag-off: rgba(255,255,255,0.5); // #fff
$tag-on: $emphase;

$bg-color-1: #169c93;//#147D76
$bg-color-2: #750075;
$bg-color-3: #13395E; // #13395E


:root {
  --emphase: #{$emphase}; // #FFFF66 #63C282 #418AB0 #C27A59
  --sidebar-background: #{$main-color}; //EEE #e7946a
  --sidebar-text: #{$text-color}; //333
  --img-brightness: 0.85;
  --page-bg1: rgba(0,0,0,0.4); //#{darken($gradient-color-2, 0%)}
  --page-bg2: rgba(0,0,0,0); //  #{darken($gradient-color-2, 10%)} - CCC
  --page-bg-angle: 180deg;
  --bar-color: white;
  --bar-bg: transparent;
  --sel-text-bg: #{$text-color};
  --sel-text-color: #{$text-color-dark};
  --link-decoration-color: #{lighten($emphase, 20%)};

  --anim-duration: .2s;
  --shadow-angle: 135deg;
  --shadow-box1: -2px 2px 5px;
  --shadow-box2: -5px 5px 10px;
  --shadow-box3: -10px 10px 20px;

  --card-text: #{$text-color};
  --card-year: #{darken($text-color, 20%)};
  --card-text-bg: rgba(0,0,0,.7);
  --card-bg1: #{darken($emphase, 20%)}; //
  --card-bg2: #{$bg-color-1}; // #{darken($gradient-color-2, 0%)}
  --card-filter: grayscale(40%) contrast(100%) brightness(.4);
  --card-filter-hover: grayscale(10%) contrast(100%) brightness(0.9);
  --tag-off1: #{$tag-off};
  --tag-off2: #{darken($tag-off, 20%)};
  --tag-off-text: black;
  --tag-on1: #{$tag-on};
  --tag-on2: #{darken($tag-on, 10%)};
  --tag-on-text: #{$text-color};
  --img-show-background: rgba(0,0,0,0.75);
  --caption-background: black;
  --caption-text:#{$text-color};
  --bg-color-1: #{$bg-color-1};
  --bg-color-2: #{$bg-color-2};
  --bg-color-3: #{$bg-color-3};
}

// @media screen and (prefers-color-scheme: light) {
[data-theme="light"] {

    --sidebar-text: #{$text-color-dark}; //333
    --img-brightness: 1;
    --sel-text-bg: #{$text-color-dark};
    --sel-text-color: #{$text-color};
    --link-decoration-color: #{$text-color};

    --shadow-angle: -45deg;
    --shadow-box1: 2px 2px 5px;
    --shadow-box2: 5px 5px 10px;
    --shadow-box3: 10px 10px 20px;

    --card-text: #{$text-color-dark};
    --card-year: #{lighten($text-color-dark, 20%)};
    --card-text-bg: rgba(255,255,255,0.5);
    --card-filter: grayscale(80%) contrast(35%) brightness(2);
    --card-filter-hover: grayscale(20%) contrast(90%) brightness(1.2);
    --card-bg1: #{lighten($emphase, 20%)}; //
    --card-bg2: #{$bg-color-1}; // #{darken($gradient-color-2, 0%)}
    // --tag-off1: #{$tag-off};
    // --tag-off2: #{darken($tag-off, 20%)};
    // --tag-off-text: #{$text-color};
    // --tag-on1: #{$tag-on};
    // --tag-on2: #{darken($tag-on, 10%)};
    // --tag-on-text: #{$text-color};

    --img-show-background: rgba(255,255,255,0.7);
    --caption-background: white;
    --caption-text:#{$text-color-dark};
    --bg-color-1: #{lighten($bg-color-1, 60%)};
    --bg-color-2: #{lighten($bg-color-2, 60%)};
    --bg-color-3: #{lighten($bg-color-3, 60%)};
  }
// }


/* FONTS */
@font-face {
    font-family: 'League Gothic';
    src: url('/css/fonts/leaguegothic-italic.woff2') format('woff2'),
         url('/css/fonts/leaguegothic-italic-webfont.woff') format('woff'),
         url('/css/fonts/leaguegothic-italic-webfont.eot') format('embedded-opentype');
    font-weight: normal;
    font-style: italic;
}

// @font-face {
//     font-family: 'Placard Condensed';
//     src: url('/css/fonts/PlacardCondensed.ttf') format('ttf');
// }
//
// @font-face {
//   font-family: 'Anton';
//   font-style: normal;
//   font-weight: 400;
//   font-display: swap;
//   src: local('Anton Regular'), local('Anton-Regular'), url('/css/fonts/Anton.woff2') format('woff2');
//   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
// }

@font-face {
  font-family: 'Comfortaa';
  font-style: normal;
  font-weight: 400;
  src: local('Comfortaa'), local('Comfortaa'), url('/css/fonts/Comfortaa_400.woff') format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Comfortaa';
  font-style: bold;
  font-weight: 700;
  src: local('Comfortaa'), local('Comfortaa'), url('/css/fonts/Comfortaa_700.woff') format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@keyframes backgroundAnim{
    0%{background-position:75% 0%}
    50%{background-position:26% 100%}
    100%{background-position:75% 0%}
}

div > p, div > a, div > h1, div > h2, .arrow-back, .arrow-top, .align-icon, #site-name > h1 {
  transition: all var(--anim-duration) ease-out;
  transition-property: background-color, border-color, color;
}

body {
  // color: var(--page-text);

  background: linear-gradient(30deg, var(--bg-color-2), var(--bg-color-3), var(--bg-color-1));
  background-attachment: fixed;
  background-size: 400% 400%;
  animation: backgroundAnim 40s ease-in-out infinite;
}

.sidebar-content {          // , .gal-items
  // scrollbar-gutter: always;
  scrollbar-width: thin;
  scrollbar-color: rgba(255,255,255,0.5) rgba(0,0,0,0);
  &::-webkit-scrollbar {
    width: 9px;
  }
  &::-webkit-scrollbar-track {
    background: rgba(0,0,0,0);
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(255,255,255,0.5);
    border-radius: 6px;
  }
}


h1, h2, h3, p, li, a, label {
  font-family: Comfortaa, Arial, sans-serif;

  &::selection {
    background-color: var(--sel-text-bg);
    color: var(--sel-text-color);
  }
}


a {
  // text-decoration: none;
  // color: inherit;

  text-decoration: underline; //wavy
  // text-decoration-color: var(--link-decoration-color);
  // color: var(--emphase);

  // background-color: var(--sidebar-text);
  color: var(--sidebar-text);
  &:hover {
    background-color: var(--emphase);
    // color: var(--sidebar-text);
  }
}

// p > a, #read-more {
//   text-decoration: underline wavy; //wavy
//   // color: var(--emphase);
//
//   background-color: var(--sidebar-text);
//   color: var(--emphase);
//   &:hover {
//     background-color: var(--emphase);
//     color: var(--sidebar-text);
//   }
// }

.main-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
}

.main-area {
  // width: 100%;

  // background-color: var(--page-background);

  // background: linear-gradient(45deg,calc(darken(var(--gradient-color2), 50%))  0%, calc(darken(rgb(253, 255, 0), 50%))  100%);
  //
  //   mix-blend-mode: multiply

  background: linear-gradient(var(--page-bg-angle), var(--page-bg2) 40%, var(--page-bg1) 100%);
}

#scroll-top {
  // position: absolute;
  width: 100%;
  height: 70px;
  bottom: 10px;
  right: 10px;
  text-align: center;
  display: block;

  a {
    display: inline-block;
    // border-radius: 50%;
    width: 100%;
    height: 100%;
    vertical-align: middle;
  }

  .arrow-top{
    display: inline-block;
    // width: 3em;
    // height: 3em;
    // background-color: rgba(0,0,0,0.4);
    // border-radius: 50%;
    // box-shadow: var(--shadow-box2) rgba(0, 0, 0, 0.3);
    &::after {
      content: '';
      display: inline-block;
      margin-top: 1.6em;
      width: 2em;
      height: 2em;
      border-top: 0.3em solid var(--emphase);
      border-right: 0.3em solid var(--emphase);
      transform: rotate(-45deg);
    }
  }
  &:hover .arrow-top::after {
    border-color: white;
  }
}

.overlay {
  // position: absolute;
  // left: 0;
  // top: 0;
  // width: 100vw;
  // height: 100vh;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // z-index: 99;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.5);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  .overlayContent{
    background-color: red;
    max-width: 450px;
    padding: 15px;
    border-radius: 10px;
    text-align: center;
    p {
      margin: 0 0 10px 0;
    }

  }
}

input#noscript-overlay {
    position: absolute;
    top: -9999px;
    left: -9999px;
}

input#noscript-overlay:checked ~ div {
  display: none !important;
}

#noscript label {

  line-height: 34px;
  padding: 0 10px;
}

/*-------------------------------------------------------------------*/
/* SIDEBAR */
/*-------------------------------------------------------------------*/
.sidebar {
  // background-color: var(--sidebar-background);
  // background: linear-gradient(var(--page-bg-angle), var(--page-bg1) 40%, var(--page-bg2) 100%);
  background: linear-gradient(130deg, var(--page-bg2) 40%, var(--page-bg1) 100%);
  color: var(--sidebar-text);

  .sidebar-content {
    margin: 0 auto;
    padding: 0 10px;
    text-align: right;
    // hyphens: auto;
    overflow-x: hidden;


    h1 {
      // font-size: 42px;
      font-size: #{"min(42px, 10vw)"};
      margin: 5px 0;
    }



    img {
      display: block;
      max-width: 100%;
      min-width: 200px;
      max-height: 300px;
      margin: 0 auto;
      filter: brightness(var(--img-brightness));
      opacity: 0.95;
      border-radius: 10px;
      transition: all var(--anim-duration) ease-out;
      transition-property: opacity, filter;
      &:hover {
        opacity: 1;
        filter: none;
      }
    }
    .sidebar-text {
      text-align: left;
      max-width: 450px;
      margin: 0 auto;
      padding-bottom: 10px;
    }

    .tag-list {
      ul {
        margin: 0;
        justify-content: flex-end;
      }
      a {
        padding: 7px;
      }
      h3 {
        font-size: 12px;
      }
    }



  }
}

#site-name {
  // position: absolute;
  text-align: left;
  margin: 0.2em 0;
  // width: 100%;
  display: inline-block;
  // color: var(--emphase);
  background: none;
  text-decoration: none;
  h1 {
    font-family: League Gothic, Impact, sans-serif;
    font-size: 32px;

    // font-family: 'Placard Condensed', Impact, sans-serif;
    // font-size: 26px;

    // font-family: Anton, Impact, sans-serif;

    font-style: italic;

    display: inline-block;
    // color: var(--emphase);
  }

  &.home {
    text-align: inherit;
    color: var(--sidebar-text);
    h1 {
      font-size: 62px;
      // color: var(--sidebar-text);
      &:hover {
        color: var(--emphase);
        background: none;
      }
    }

    .arrow-back {
      display: none;
    }
  }

  &:not(.home) {
    h1 {
      color: var(--emphase);
    }
  }
  &:hover {
    h1 {
      color: var(--sidebar-text);

    }
    // background-color: var(--emphase);
    .arrow-back {
      border-color: var(--sidebar-text)
    }

    // h1 {
    //   color: var(--sidebar-text);
    // }
    // .arrow-back {
    //   border-color: var(--sidebar-text);
    // }
  }

}
.arrow-back {
  display: inline-block;

  //Arrow 1 (triangle)
  // width: 0;
  // height: 0;
  // border-style: solid;
  // border-width: 16.5px 43px 16.5px 0;
  // border-color: transparent #ff0000 transparent transparent;

  //Arrow 2 (angle)
  border: solid var(--emphase);
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 9px;
  margin-left: 5px;
  transform: rotate(135deg);
}


.sidebar-utility {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: flex-start;
  margin: -12px 0 15px -6px;
}

.sb-util {
  display: inline-block;
  position: relative;
  height: 34px;
  margin: 5px;
  padding: 0;
  border-radius: 34px;
  // background: linear-gradient(135deg, var(--tag-off1), var(--tag-off2));
  background-color: var(--tag-off1);
  box-shadow: var(--shadow-box2) rgba(0, 0, 0, 0.3);

  text-decoration: inherit;
  color: var(--tag-off-text);

  // transition: all var(--anim-duration) ease-out;
  // transition-property: transform;
  a {
    text-decoration: inherit;
    color: inherit;
  }
  h2,p {
    font-size: inherit;
    font-weight: normal;
    margin: 8px;
  }
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    opacity: 0;
    border-radius: 34px;
    box-shadow: var(--shadow-box3) rgba(0, 0, 0, 0.4);
    opacity: 0;
    transition: all var(--anim-duration) ease-out;
    transition-property: opacity;
  }
  &:hover::after {
    opacity: 1;
  }
  &:hover {
    color: var(--tag-on-text);
    // background: linear-gradient(135deg, var(--tag-on1), var(--tag-on2));

    // box-shadow: rgba(50,50,93,0.25) 0 30px 60px -12px;
    // transform: translateY(-2px);
    // transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
  }
}
#contact-me {
}
#info {
  // width: 34px;
  // text-align: center;
}

.switch {
  position: relative;
  width: 66px;
  margin: 0 5px;
  &:after {
    border-radius: 34px;
  }
}

.switch input {
  display:none;
}

.slider {
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  // transition: all var(--anim-duration) ease-out;
  // transition-property: color, background;
  // background-color: var(--tag-off1);
  // background: linear-gradient(135deg, #{$main-color}, #{darken($main-color, 20%)});

  // box-shadow: var(--shadow-box2) rgba(0, 0, 0, 0.5);
}

.slider:before {
  background-color: var(--emphase);
  bottom: 4px;
  content: "";
  height: 26px;
  left: 4px;
  position: absolute;
  transition: var(--anim-duration);
  width: 26px;
  box-shadow: inset 2px -2px 5px rgba(0,0,0,0.5);
}

input:checked + .slider:before {
  // background-color: var(--emphase);
  box-shadow: inset -2px -2px 5px rgba(0,0,0,0.5);
}

input:hover {
  &+ .slider {
    // background: linear-gradient(135deg, #{$main-color}, #{darken($main-color, 20%)});

  }

  &+ .slider:before {
    box-shadow: inset 2px 2px 5px rgba(0,0,0,0.5), var(--shadow-box1) rgba(0, 0, 0, 0.8);
  }
}

input:checked + .slider {
  // background-color: #{$main-color};
  // background: linear-gradient(135deg, var(--tag-off1), var(--tag-off2));

}

input:checked:hover {
  &+ .slider {
    // background: linear-gradient(135deg, #{$main-color}, #{darken($main-color, 20%)});
  }
  &+ .slider:before {
    box-shadow: inset -2px -2px 5px rgba(0,0,0,0.5), var(--shadow-box1) rgba(0, 0, 0, 0.8);
  }
}

input:checked + .slider:before {
  transform: translateX(32px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}


.bubble-wrapper{
  display: none;
  width: 100%;
  margin-top: 10px;

  .bubble {
    display: flex;
    position: relative;
    max-width: 300px;
    margin-left: -10px;
    float: right;
    background: var(--tag-off1);
  	border-radius: .4em;
    transform: translate(0px, -7px);
    // transform: translateX(calc(-100% - 20px));
    // max-width: 25%;
    // box-shadow: var(--shadow-box2) rgba(0, 0, 0, 0.5);
    color: var(--tag-off-text);
    p {
      margin: 10px;
    }
  }

  .bubble:after {
  	content: '';
  	position: absolute;
  	bottom: 0;
  	width: 0;
  	height: 0;
  	border: 10px solid transparent;
  	border-top-color: var(--tag-off1);
  	// border-right: 0;
    margin-bottom: -20px;
  	// margin-left: 35px;
    right: 28px;
  }
}




/*-------------------------------------------------------------------*/
/* PROJECTS */
/*-------------------------------------------------------------------*/
.tag-list {
  pointer-events: none;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 0 10px;
  z-index: 2;


  ul {
    width: 100%;
    // margin: .2em auto;
    list-style: none;
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;


    padding: 0;
    // list-style-type: none;

    li {
      display: block;

      // background: #eef0f4;
      // box-shadow: inset 5px 5px 10px #a6a6a6,inset -5px -5px 10px #fff;

      p, h1, h3 {
        margin: 0;
        font-size: 16px;
        font-weight: normal;
      }

      &>a {
        color: inherit;
        background-color: inherit;
        text-decoration: none;

        position: relative;
        display: block;


        // background: linear-gradient(135deg, var(--tag-off1), var(--tag-off2));
        background-color: var(--tag-off1);
        box-shadow: var(--shadow-box2) rgba(0, 0, 0, 0.3);


        color: var(--tag-off-text);
        border-radius: 10px;
        padding: 10px;
        margin: 5px;
        cursor: pointer;
        transition: all var(--anim-duration) ease-out;
        transition-property: font-size, color, background-color, transform;
        will-change: transform;
        pointer-events: auto;

        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          z-index: -1;
          width: 100%;
          height: 100%;
          opacity: 0;
          border-radius: 10px;
          box-shadow: var(--shadow-box3) rgba(0, 0, 0, 0.4);
          opacity: 0;
          transition: opacity, var(--anim-duration) ease-out;
        }
        &:hover::after {
          opacity: 1;
        }

      }

      &.tag{
        transform: skew(-20deg);
        p, h1, h3 {
          transform: skew(20deg);
        }
      }
      &#align-tags {
        margin-right: 45px;
        .align-icon {
          transform: skew(20deg);
          .align-icon-wrapper {
            transform: skew(-20deg);
          }
        }

        &.active .align-icon {
          transform: skew(-20deg);
          .align-icon-wrapper {
            transform: skew(20deg);
          }
          .align-icon-bar {
            background-color: var(--tag-on-text);
          }
        }
      }

      &.active a, &:hover a {
        // background-color: black;
        color: var(--tag-on-text);
        // background: linear-gradient(135deg, var(--tag-on1), var(--tag-on2));
        background-color: var(--tag-on1);

        // box-shadow: inset 8px 8px 23px #b0b0b0,
        //     inset -8px -8px 23px #eeeeee;

      }



    }

  }

}

#align-tags {
  padding: 0 10px;
  // display: block;
  // height: 20px;
}
.align-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 36px;
  // height: 100%;
  // margin: 5px 0;
  // box-sizing: border-box;
  background-color: rgba(0,0,0,0);
  &:hover {
    #aib1 {width: 75%}
    #aib2 {width: 75%}
    #aib3 {width: 75%}
  }
}
.align-icon-bar {
  background-color: var(--tag-off-text);
  height: 3px;
  margin : 0 auto;
  transition: all var(--anim-duration) ease-out;
  transition-property: width border-color;
  border-radius: 2px;
}
#aib1 {width: 60%}
#aib2 {width: 100%; margin: 4px auto}
#aib3 {width: 40%}


#align-tags.active {
  #aib1 {width: 75%}
  #aib2 {width: 75%}
  #aib3 {width: 75%}
  &:hover {
    #aib1 {width: 60%}
    #aib2 {width: 100%}
    #aib3 {width: 40%}
  }
}



.project-list {

  // display: flex;
  // flex-wrap: wrap;
  // justify-content: center;
  // align-content: center;
  text-align: center;
  padding-bottom: 20px;

}

.project-card-shuffle-sizing {
  margin-left: 10px;
  width: 1px;
  // height: 130px;
}






.project-card {
  position: relative;
  overflow: hidden;
  // width: 95%;
  min-width: 250px;
  max-width: 500px;
  height: 130px;
  margin: 10px;
  border-radius: 10px;

  display: inline-block;

  box-shadow: var(--shadow-box2) rgba(0, 0, 0, 0.3);

  transition: all var(--anim-duration) ease-out;
  transition-property: box-shadow; // transition not working, don't know why

  img, .card-filter, .project-card-text, .project-card-tags, .project-card-year {
    transition: all var(--anim-duration) ease-out;
  }

  .project-card-text, .project-card-tags {
    transition: background-color var(--anim-duration) ease-out;
  }

  img, .card-filter {
    position: absolute;
    left: 0;
    width: 100%;
    min-height: 100%;

    top: 50%;
    transform: translateY(-50%);
  }

  img {

    filter:  var(--card-filter);
    opacity: 0.7;
    // transition-property: filter, opacity;
    // transition-property: none;
  }
  .card-filter {
    height: 100%;
    background: linear-gradient(15deg,var(--card-bg1) 0%, var(--card-bg2) 100%);
    // mix-blend-mode: color; //Causing a bug on chrome and firefox during transition
    // opacity: 0.6;

    transition: opacity var(--anim-duration) ease-out;
    // transition-property: none;
  }


  a {
    color: var(--card-text);
    text-decoration: none;
  }

  .project-card-content {
    // position: absolute;
    // top: 0;
    // left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    padding: 10px;
    box-sizing:border-box;

  }

  .project-card-text{
    width: 70%;
    height: 100%;
    z-index: 1;

    // display: inline-block;

    h2 {
      margin: 0;
      font-size: 1.5em;
      line-height: 28px;
      text-align: left;
    }

    .project-card-year{
      // margin: 5px 0 0 0;
      margin: 0;
      padding: 5px 0 0 0;
      color: var(--card-year);
      text-align: left;
    }
  }

  .project-card-tags {

    flex-basis: 30%;

    margin:  0;
    padding: 0;

    list-style: none;

    display: block;
    text-align: right;
    font-size: .8em;
    opacity: .7;
    li {
      // display: inline-block;
      text-align: right;
    }

  }

  &.justify-card {
    width: 400px;
  }

  &:hover {

    box-shadow: var(--shadow-box3) rgba(0, 0, 0, 0.4);
    // transform:scale(2);
    img {
      filter:  var(--card-filter-hover);
      opacity: 1;
    }
    .card-filter {
      opacity: (0.3);
    }

    h2 > span {
      background-color: var(--card-text-bg);
    }

    .project-card-tags, .project-card-year {
      opacity: 0;
    }


    // .project-card-text, .project-card-tags {
    //   opacity: 0.5;
    // }
  }
  // &::after {
  //   content: '';
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   z-index: -1;
  //   width: 100%;
  //   height: 100%;
  //   opacity: 0;
  //   border-radius: 5px;
  //   box-shadow: var(--shadow-box3) rgba(0, 0, 0, 0.8);
  //   opacity: 0;
  //   transition: opacity, 0.3s ease-out;
  // }
  // &:hover::after {
  //   opacity: 1;
  // }

}



/*-------------------------------------------------------------------*/
/* MEDIA GALLERY */
/*-------------------------------------------------------------------*/
.img-gal {
  width: 100%;
  background-color: var(--img-show-background);
  text-align: center; //Only needed for the scroll-top arrow

  .gal-items {
    display: flex;
    flex-wrap: nowrap;
    align-content: flex-start;
    width: 100vw;
    height: 20vh;
    position: relative;
    // overflow-y: hidden;

    .gal-item {
      position: relative;
      height: 20vh;
      cursor: pointer;


      background-color: var(--caption-background);

      .item-wrap {
        height: 20vh;
        filter: saturate(25%);
        transition: all var(--anim-duration) ease-out;
        transition-property: transform, filter;

        p {
          // font-size: #{"min(42px,10vw)"};
          font-size: 22px;
          padding: 10px;
        }
      }

      img {
        display: block;
        width: auto;
        height: 100%;
      }

      &.text {

        .item-wrap {
          width: 150px;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          color: var(--sidebar-text);
        }
        &:nth-child(3n) .item-wrap {
          background-color: var(--bg-color-1);
        }
        &:nth-child(3n+1) .item-wrap {
          background-color: var(--bg-color-2);
        }
        &:nth-child(3n+2) .item-wrap {
          background-color: var(--bg-color-3);
        }
      }
      .caption-data, .text-data {
        display: none;
      }


      &.selected-item {

        background-color: transparent;

        .item-wrap {

          transform: perspective(200px) translateZ(-120px) rotate(13deg);
          filter: saturate(100%);
        }
        .play-button:after {
          border-left-color: var(--emphase);
        }

        .gal-item-overlay {
          // display: block;
        }

      }
      // The hover transform is not applied on selected item. This alows the item to be properly displayed as selected on iOS (which by default keep hover on previously touched item)
      &:hover:not(.selected-item) {
        // transform: perspective(200px) translateZ(20px) rotate(-5deg);
        .item-wrap {
          filter: saturate(100%);
        }
        .play-button:after {
          border-left-color: var(--emphase);
        }

      }
    }

    &.items-strech {
      .gal-item {
        flex-grow: 1;
        &.text .item-wrap{
          width: auto;
        }
      }
    }


  }

  .img-show {
    width: 100vw;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    // background-color: var(--img-show-background);

    // position: relative;

    .img-show-media-wrapper {

      width: 100%;
      height: auto;



    }

    img, iframe, video {
      // width: 100%;
      max-width: 100%;
      // max-height: 100vh;
      height: auto;
    }
    iframe {
      min-height: 450px;
    }



    .img-show-caption, .img-show-text {
      background-color: var(--caption-background);
      color: var(--caption-text);
      width: 100%;
      padding: 0 10px;
      box-sizing: border-box;
      text-align: right;
    }

    .img-show-text {
      background-color: transparent;
      text-align: left;
      overflow: auto;
      ul {
        line-height: 1.5;
      }
    }

  }

}

.play-button {
		//circle
		position: absolute;
		top: calc(50% - 25px);
		left: calc(50% - 25px);
		width: 50px;
		height: 50px;
		display: block;
		border-radius: 50%;
		padding: 0;
		margin: 0 auto;
		user-select: none;
		background-color: rgba(0, 0, 0, 0.6);
		transition: all var(--anim-duration) ease-out;
    transition-property: background-color border-color;
		&:after {
			//play button
			position: absolute;
			top: calc(50% - 13px);
			left: calc(50% - 7px);
			display: block;
			content: '';
			box-sizing: border-box;
			border-color: transparent transparent transparent rgba(255,255,255,0.8);
			border-style: solid;
			border-width: 13px 0 13px 20px;
      transition: border-color var(--anim-duration) ease-out;
		}
		&:hover {
			// background-color: inherit;
		}
	}

/*-------------------------------------------------------------------*/
/* RESPONSIVENESS */
/*-------------------------------------------------------------------*/

@media screen and (min-width: 900px) {

  :root{
    --page-bg-angle: 0deg;
  }

  body {
    overflow-x: hidden;
  }

  .main-page {
    flex-direction: row;
  }

  .sidebar {
    position: fixed;
    width: 40vw;

    .sidebar-utility {
      flex-direction: row;
      // justify-content: flex-start;
      // justify-content: flex-end;
      .bubble {
        float: left;
        &::after {
          right: auto;
          left: 38px;
        }
      }

    }

    .sidebar-content {
      height: 100vh;
      // overflow-x:auto;
      margin-left: 25%;
      padding: 0 10px 0 20px;
      // margin-right: 10px;
      text-align: left;

      #site-name.home {
        h1 {
          font-size: 6vw;
        }
      }

      h1 {
        font-size: 3.5vw;
        font-size: #{"max(3.5vw, 35px)"};
      }

      img {
        margin: 0;
      }
      .sidebar-text {
        margin: 0;
        max-width: 500px;
      }

      .tag-list ul{
        justify-content: flex-start;
      }

    }
  }

  .title {
    font-size: 4em;
  }
  .main-area {
    position: absolute;
    left: 40vw;
    width: 60vw;
    min-height: 100vh;
    .tag-list {
      position: sticky;
      top: 0;

      // z-index: 1;
      ul {
        // margin: inherit;
      }
    }
  }

  .project-card {
    // width: 95%;
    h2 {
      font-size: 2em;
    }
  }

  .img-gal {
    height: 100vh;
    .gal-items {
      flex-wrap: wrap;
      width: 20vw;
      max-width: 400px;
      height: 100vh;
      overflow-x: hidden;
      overflow-y: auto;
      float: left;

      .gal-item {
        width: 100%;
        height: auto;
        overflow: hidden;

        .item-wrap {
          width: 100%;
          height: auto;
          p {
            font-size: 2vw;
          }

        }

        img {
          width: 100%;
          height: auto;
        }
        &.text {
          width: 100%;
          // height: 150px;
          .item-wrap {
            width: 100%;
            // height: 150px;
          }
        }

        &:hover:not(.selected-item) {
          .item-wrap {
            transform: scale(1.2) rotate(-3deg);
          }

        }
      }

      &.items-strech {
        align-content: stretch;
        .gal-item, .gal-item.text {
          height: auto;
          .item-wrap {
            min-height: 100%;
            height: inherit;
          }
        }
        .gal-item.text .item-wrap {
          // height: inherit;

        }

      }
    }
    .img-show {
      // position: absolute;
      // top: 0;
      // left: 20vw;

      width: 40vw;
      height: 100vh;
      flex-direction: column-reverse;
      .img-show-media {

      }
      .img-show-caption {

      }
      .img-show-text {
        max-width: 700px;
      }

      iframe {
        height: 100%;
        min-height: 200px;
      }
      img, video {
        // max-height: 100%;
        min-height: 0;
        object-fit: contain;
      }


    }
  }

  #scroll-top {
    display: none !important;
  }

}

@media screen and (max-width: 660px) {
  #align-tags {
    display: none;
  }

  .sidebar .sidebar-content h1 {
    // font-size: 10vw;
  }
  .project-card {
    width: 90%;
    &.justify-card {
      width: 90%;
    }
  }
}
